import React, { useState } from "react";
import allApi from "../../../api/allApi";
import inclogo from "../../../assets/images/inc-name-logo.png";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [resp,setResp] = useState(null);
  

  const handleSendClick = async (e) => {
    e.preventDefault();
    
     
    try {
      const api = new allApi();
      const payload = {email};
      const response = await api.postRequest("api/forgotPassword",payload);
     
      if (response) {
        setResp(response);
        console.log('Email sent successfully:', response);
      }else{
        console.error("Failed in sending mail");
      }
    }catch (error) {
      
        console.error('Failed to send email:',  error);
      } 
  };

  const userEmail = async (e) => {
    setEmail(e.target.value);
  };
  const navigate=useNavigate();
  const handleBack=()=>{
    navigate('/');
  }


  return (
    <div className="inc-login">
      <div className="login-form">
        <div className="login-form-container">
          <div className="login-text">
            <img src={inclogo} alt="inc logo" className="inc-logo" />
            <h1>Forgot Password?</h1>
            <p>
              {" "}
              Don&apos;t worry!, Update your password <br />
              with registered email.
            </p>
          </div>
          <div className="inc-form line-textbox">
            <input
              type="email"
              placeholder="example@domain.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-login"
            onClick={handleSendClick}
          >
            Send
          </button>
          <div className="social-login">
            <div className="s-text" onClick={handleBack}>
              <span className="back-login">Back to Login</span>
            </div>
            </div>
        </div>
        
      </div>
      <div className="login-banner">
        <div>
          <h3>About INC</h3>
          <p>
            Highly scalable, elastic, smart cloud platform for networking
            devices for data aggregation, analytics, visualization, and
            management.
          </p>

          <p>
            By building interconnectivity standards, INC bridges the needs of
            brands, OEMs and developers across industries to fast track their
            cloud development using reusable components like SDKs, streamers,
            protocol connectors and dashboard widget.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
