import React from "react";

function ipSettings() {
  return (
    <div>
      <div className="tab-form">
        <div className="row mb-4">
          <div className="col-lg-4">
            <div className="switch-box">
              <strong>Assign IP Address Automaticaly</strong>
              <label className="ice-switch">
                <input type="checkbox" />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4">
            <label for="ipaddress" className="col-form-label">
              IP Address
            </label>
            <input type="text" className="form-control" placeholder="192.168.11.1" />
          </div>
          <div className="col-lg-4">
            <label for="subnetmask" className="col-form-label">
              Subnetmask
            </label>
            <input type="text" className="form-control" placeholder="255.255.240.0" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4">
            <label for="subnetmask" className="col-form-label">
              Primary DNS
            </label>
            <input type="text" className="form-control" placeholder="255.255.240.0" />
          </div>
          <div className="col-lg-4">
            <label for="ipaddress" className="col-form-label">
              Secondary DNS
            </label>
            <input type="text" className="form-control" placeholder="192.168.11.1" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-4">
            <label for="ipaddress" className="col-form-label">
              Gateway Address
            </label>
            <input type="text" className="form-control" placeholder="192.168.11.1" />
          </div>
        </div>
      </div>
      <div className="wifi-footer">
        <button type="button" className="btn btn-outline">
          Cancel
        </button>
        <button type="submit" className="text-btn primary-btn">
          Save
        </button>
      </div>
    </div>
  );
}

export default ipSettings;