import React from "react";

function radiusConfiguration() {
  return (
    <div className="inc-card-body">
      <div className="under-development">RADIUS Configuration</div>
    </div>
  );
}

export default radiusConfiguration;