import React, { useEffect, useState } from "react";
import allApi from "../../../../api/allApi";
import Loader from "../../common/Loader";
import errorIcon from "../../../../assets/images/error-2.png";

function VlanNetworkSetup() {
  const [vlanList, setVlanList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vlanId, setVlanId] = useState("");

  useEffect(() => {
    fetchVlanList();
  }, []);

  const fetchVlanList = async () => {
    try {
      const api = new allApi();
      const response = await api.getRequest("api/vlan/list");
      setVlanList(response);
    } catch (error) {
      console.error("Failed to fetch VLAN list:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteVlan = async (vlan_id) => {
    try {
      setLoading(true);
      const api = new allApi();
      await api.deleteRequest(`api/vlan/del/vlan/${vlan_id}`);
      await fetchVlanList();
    } catch (error) {
      console.error("Failed to delete VLAN", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="inc-card-table">
        <table className="m-head snmp-table">
          <thead>
            <tr>
              <th>VLAN Name</th>
              <th>VLAN ID</th>
              <th>Description</th>
              <th>Network Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {vlanList.map((vlan) => (
              <tr key={vlan.vlan_id || "N/A"}>
                <td>{vlan.vlan_name || "N/A"}</td>
                <td>{vlan.vlan_id || "N/A"}</td>
                <td>{vlan.description !== "N/A" ? vlan.description : "-"}</td>
                <td>{vlan.network_type || "N/A"}</td>
                <td>
                  <div className="button-group">
                    <button className="icon-btn" title="Edit">
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </button>
                    <button
                      className="icon-btn"
                      title="Delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteSsid"
                      onClick={() => setVlanId(vlan.vlan_id)}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className={`modal fade`}
        id="deleteSsid"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content inc-modal">
            <div className="modal-body success-msg">
              <img src={errorIcon} alt="error" />
              <p>Are you sure to delete {vlanId} VLAN?</p>
              <button
                className="text-btn primary-btn"
                data-bs-dismiss="modal"
                onClick={() => deleteVlan(vlanId)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VlanNetworkSetup;
