import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/inc-name-logo.png";
import vvdnLogo from "../../../assets/images/VVDN.png";
import cpPlusLogo from "../../../assets/images/CPPlus.png";
import iceLogo from "../../../assets/images/ICE.png";
import { jwtDecode } from "jwt-decode";

const Sidebar = () => {
  const [organization, setOrganization] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeviceDropdownOpen, setIsDeviceDropdownOpen] = useState(false);

  useEffect(() => {
    decodeOrganization();
  }, []);

  const decodeOrganization = () => {
    let jwtToken = sessionStorage.getItem("sessionToken");
    if (jwtToken) {
      try {
        let decodedToken = jwtDecode(jwtToken);
        setOrganization(decodedToken?.organization);
      } catch (error) {
        console.error("Failed to decode token:", error);
        setOrganization("");
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleDeviceDropdown = () => {
    setIsDeviceDropdownOpen(!isDeviceDropdownOpen);
  };
  return (
    <div className="navbar-menu">
      <div className="navbar-brand-box">
        {/* <span>INC</span> */}
        <img
          src={
            organization == "VVDN Technologies"
              ? vvdnLogo
              : organization == "CP PLUS INDIA"
              ? cpPlusLogo
              : organization == "Intelligent Cloud"
              ? iceLogo
              : logo
          }
          alt="inc logo"
        />
        {/* {organization && <span>{organization}</span>} */}
      </div>
      <div className="simplebar-scrollable-y">
        <nav className="inc-navigation">
          <ul>
            <li>
              <NavLink activeclassname="active" to="/overview">
                <i className="dashboard icon"></i> Overview
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/tenant-management">
                <i className="account_circle icon"></i> Tenant Management
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/user-management">
                <i className="supervisor_account icon"></i> User Management
              </NavLink>
            </li>
            <li className={`dropdown ${isDeviceDropdownOpen ? "open" : ""}`}>
              <button
                activeclassname="active"
                onClick={() => toggleDeviceDropdown()}
              >
                <i className="router icon"></i> Device Management{" "}
                <i
                  className={`fa ${
                    isDeviceDropdownOpen ? "fa-angle-up" : "fa-angle-down"
                  }`}
                ></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <NavLink activeclassname="active" to="/device-listing">
                    Devices
                  </NavLink>
                </li>
                <li>
                  <NavLink activeclassname="active" to="/device-link">
                    Device Link
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
              <button
                activeclassname="active"
                to=""
                onClick={() => toggleDropdown()}
              >
                <i className="alpha-d-box icon"></i> Model Management{" "}
                <i
                  className={`fa ${
                    isDropdownOpen ? "fa-angle-up" : "fa-angle-down"
                  }`}
                ></i>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <NavLink
                    activeclassname="active"
                    to="/modal-management/create-template"
                  >
                    SNMP
                  </NavLink>
                </li>
                <li>
                  <NavLink activeclassname="active" to="/modal-management/mqtt">
                    MQTT
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    to="/modal-management/tr069"
                  >
                    TR-069
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    to="/modal-management/https"
                  >
                   HTTPS
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink activeclassname="active" to="/edge-management">
                <i className="devices_other icon"></i> Edge Management
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/wired">
                <i className="wired icon"></i> Wired
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/wireless">
                <i className="wireless icon"></i> Wireless
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/client">
                <i className="client icon"></i> Client
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/troubleshoot">
                <i className="troubleshoot icon"></i> Troubleshoot
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/alarms">
                <i className="fault icon"></i> Alerts
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/topology">
                <i className="domain icon"></i> Topology
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/OTAManagement">
                <i className="memory icon"></i> OTA Management
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/reports">
                <i className="memory icon"></i> Reports
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/audits">
                <i className="icon track_changes"></i> Audits
              </NavLink>
            </li>
            <li>
              <NavLink activeclassname="active" to="/system-info">
                <i className="settings icon"></i> System Information
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
