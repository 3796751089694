import React, { useState, useEffect } from "react";
import Auth from "../../../../assets/images/Auth.png";
import Unauth from "../../../../assets/images/Unauth.png";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";

function WiredSettings({ data, setData, serialNumber }) {
  const [formData, setFormData] = useState({
    vlanId: "",
    serialNumber: serialNumber || "",
    taggedPorts: "",
    untaggedPorts: "",
  });
  const navigate = useNavigate();
  const [portDetails, setPortDetails] = useState([]);

  useEffect(() => {
    if (data) {
      setFormData({
        vlanId: data.vlanId || "",
        serialNumber: data.serialNumber || serialNumber || "",
        taggedPorts:
          data.vlanInfo[0]?.vlanMembers?.portMembers?.taggedPorts.join(",") ||
          "",
        untaggedPorts:
          data.vlanInfo[0]?.vlanMembers?.portMembers?.untaggedPorts.join(",") ||
          "",
      });
    }
    getPortStatusClass();
  }, [data, serialNumber]);

  useEffect(() => {
    const fetchPortDetails = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest(
          `api/vlan/get/accessPort?serialNo=3R81945SF0007`
        );
        setPortDetails(response?.data?.portMembers || []);
      } catch (error) {
        console.error("Failed to fetch port details:", error);
      }
    };

    if (serialNumber) {
      fetchPortDetails();
    }
  }, [serialNumber]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const api = new allApi();
      const payload = {
        vlanId: parseInt(formData.vlanId, 10),
        vlanInfo: [
          {
            serialNumber: formData.serialNumber,
            vlanMembers: {
              portMembers: {
                taggedPorts: formData.taggedPorts
                  .split(",")
                  .map((port) => port.trim()),
                untaggedPorts: formData.untaggedPorts
                  .split(",")
                  .map((port) => port.trim()),
              },
            },
          },
        ],
      };
      const response = await api.postRequest(
        "api/vlan/add/accessPort",
        "",
        payload
      );
      if (response.ok) {
        alert("Port information added successfully!");
        navigate("/wired-config");
      } else {
        throw new Error("Failed to add port information.");
      }
    } catch (error) {
      console.error("Submission failed", error);
    }
  };

  const getPortStatusClass = (tagged) => {
    return tagged ? "assign" : "";
  };

  const renderPorts = () => {
    const portCount = 24;
    return (
      <div className="mxw-1260">
        <div className="polygon"></div>
        <div className="port-box">
          <ul className="port24">
            {[...Array(portCount).keys()].map((i) => {
              const portNumber = i + 1;
              const portDetail = portDetails.find(
                (p) => p.port === `${portNumber}`
              );
              const portClass = `port ${
                portDetail?.tagged === "true" ? "" : "tagged"
              }`;
              return (
                <li key={portNumber} className={getPortStatusClass("tagged")} onClick={()=>getPortStatusClass()}>
                  {portNumber}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h4>Wired Settings</h4>
      <p>Set where the VLAN will be applied.</p>

      <div className="accordion mt-4" id="accordionWidgets">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Port Members ({portDetails?.length})
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="port-member">
                <ul>
                  <li>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Assigned
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected & Powered
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </li>
                  <li>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Error
                  </li>
                  <li>
                    <i className="fa fa-bolt colorGreen" aria-hidden="true"></i>
                    PoE
                  </li>
                  <li>
                    <strong className="colorBlack">L</strong>LAG Member
                  </li>
                  <li>
                    <strong className="colorBlack">S</strong>1G SFP Fiber Port
                  </li>
                  <li>
                    <strong className="colorBlack">S+</strong>10G SFP+Fiber Port
                  </li>
                  <li>
                    <i
                      className="fa fa-caret-up colorBlue"
                      aria-hidden="true"
                    ></i>
                    Uplink Port
                  </li>
                  <li>
                    <img src={Auth} alt="Authorized" />
                    Authorized
                  </li>
                  <li>
                    <img src={Unauth} alt="Unauthorized" />
                    Unauthorized
                  </li>
                  <li>
                    <strong className="colorRed">!</strong>Admin Down
                  </li>
                  <li>
                    <i className="fa fa-ban colorRed" aria-hidden="true"></i>
                    Blocked
                  </li>
                </ul>
              </div>
              <div className="mt-4">
                <div className="mb-4 router-port">{renderPorts()}</div>
                <div className="port-status">
                  <div>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </div>
                  <div>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Connected
                  </div>
                  <div>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected and Powered
                  </div>
                  <div>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </div>
                  <div>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Disconnected
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Advanced Settings
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionWidgets"
          >
            <div className="accordion-body">
              <div className="row tab-form mb-2">
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Voice Optimization</strong>
                    <label className="ice-switch">
                      <input type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="switch-box">
                    <strong>Video Optimization (IGMP Snooping)</strong>
                    <label className="ice-switch">
                      <input type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row tab-form mb-4">
                <div className="col-lg-6">
                  <label className="col-form-label">Traffic</label>
                  <select className="form-select">
                    <option>dummy</option>
                    <option>dummy</option>
                    <option>dummy</option>
                    <option>dummy</option>
                  </select>
                </div>
              </div>
              <div className="port-member">
                <ul>
                  <li>
                    <i
                      className="fa fa-circle colorBlue"
                      aria-hidden="true"
                    ></i>
                    Assigned
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorGreen"
                      aria-hidden="true"
                    ></i>
                    Connected & Powered
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorLightGray"
                      aria-hidden="true"
                    ></i>
                    Disabled
                  </li>
                  <li>
                    <i
                      className="fa fa-circle colorBlack"
                      aria-hidden="true"
                    ></i>
                    Available
                  </li>
                  <li>
                    <i className="fa fa-circle colorRed" aria-hidden="true"></i>
                    Error
                  </li>
                  <li>
                    <i className="fa fa-bolt colorGreen" aria-hidden="true"></i>
                    PoE
                  </li>
                  <li>
                    <strong className="colorBlack">L</strong>LAG Member
                  </li>
                  <li>
                    <strong className="colorBlack">S</strong>1G SFP Fiber Port
                  </li>
                  <li>
                    <strong className="colorBlack">S+</strong>10G SFP+Fiber Port
                  </li>
                  <li>
                    <i
                      className="fa fa-caret-up colorBlue"
                      aria-hidden="true"
                    ></i>
                    Uplink Port
                  </li>
                  <li>
                    <img src={Auth} alt="Authorized" />
                    Authorized
                  </li>
                  <li>
                    <img src={Unauth} alt="Unauthorized" />
                    Unauthorized
                  </li>
                  <li>
                    <strong className="colorRed">!</strong>Admin Down
                  </li>
                  <li>
                    <i className="fa fa-ban colorRed" aria-hidden="true"></i>
                    Blocked
                  </li>
                </ul>
              </div>
              <div className="port-button">
                <button type="button" className="btn btn-fill">
                  Auto
                </button>
                <button type="button" className="btn btn-fill">
                  Authorized
                </button>
                <button type="button" className="btn btn-fill">
                  Unauthorized
                </button>
              </div>

              <div className="row mt-4 tab-form">
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>MAC ACL</strong>
                    <label className="ice-switch">
                      <input type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label className="col-form-label">Policy</label>
                    <select className="form-select">
                      <option>Allow</option>
                      <option>Deny</option>
                    </select>
                  </div>
                  <div className="port-button">
                    <button type="button" className="btn btn-fill">
                      Manual
                    </button>
                    <button type="button" className="btn btn-fill">
                      Confirm
                    </button>
                    <button type="button" className="btn btn-fill">
                      Add Device
                    </button>
                    <button type="button" className="btn btn-fill">
                      Remove device
                    </button>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="switch-box">
                    <strong>Enable IP Filtering</strong>
                    <label className="ice-switch">
                      <input type="checkbox" />
                      <span className="slider"></span>
                    </label>
                  </div>
                  <div>
                    <label className="col-form-label">Policy</label>
                    <select className="form-select">
                      <option>Allow</option>
                      <option>Deny</option>
                    </select>
                  </div>
                  <div className="port-button">
                    <button type="button" className="btn btn-fill">
                      Manual
                    </button>
                    <button type="button" className="btn btn-fill">
                      Confirm
                    </button>
                    <button type="button" className="btn btn-fill">
                      Add Device
                    </button>
                    <button type="button" className="btn btn-fill">
                      Remove device
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default WiredSettings;
