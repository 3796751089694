import React from "react";

function cableTest() {
  return (
    <div className="inc-card-body">
      <div className="under-development">Cable Test</div>
    </div>
  );
}

export default cableTest;