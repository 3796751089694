import React, { useState } from "react";
import OTAs from "../../../assets/images/ota-sc.png";
import OTAupi from "../../../assets/images/ota-upi.png";
import OTAupd from "../../../assets/images/ota-upd.png";
import OTAf from "../../../assets/images/ota-fl.png";

function OTAManagement() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [time, setTime] = useState(null);

  const toggleSwitch = () => {
    setIsEnabled(!isEnabled);
  };

  const handleSave = () => { };

  const handleCancel = () => { };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <ul className="nav nav-underline" id="myTab" role="tablist">              
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#overview"
                  type="button"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >
                  Overview
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="firmware-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#firmware"
                  type="button"
                  role="tab"
                  aria-controls="firmware"
                  aria-selected="false"
                >
                  Firmware
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="scheduled-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#scheduled"
                  type="button"
                  role="tab"
                  aria-controls="scheduled"
                  aria-selected="false"
                >
                  Scheduled
                </button>
              </li>
            </ul>
          </div>
          <div className="inc-card-body">
            <div className="tab-content" id="myTabContent">            
              <div
                className="tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="overview-tab">
                <div className="ota-graphics">
                  <div className="ota-card dtype-count">
                    <div>
                      <strong>60</strong>
                      <span className="snmp">SNMP</span>
                    </div>
                    <div>
                      <strong>60</strong>
                      <span className="mqtt">MQTT</span>
                    </div>
                    <div>
                      <strong>60</strong>
                      <span className="tr069">TR-069</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="sc">
                        <img src={OTAs} alt="Device Schedule" />
                      </span>
                    </div>
                    <div>
                      <strong>23</strong>
                      <span>Device Schedule</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="upi">
                        <img src={OTAupi} alt="Device Updating" />
                      </span>
                    </div>
                    <div>
                      <strong>15</strong>
                      <span>Device Updating</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="fl">
                        <img src={OTAf} alt="Device Failed" />
                      </span>
                    </div>
                    <div>
                      <strong>03</strong>
                      <span>Device Failed</span>
                    </div>
                  </div>
                  <div className="ota-card ota-count">
                    <div>
                      <span className="upd">
                        <img src={OTAupd} alt="Device Updated" />
                      </span>
                    </div>
                    <div>
                      <strong>82</strong>
                      <span>Device Updated</span>
                    </div>
                  </div>
                </div>
                <div className="ota-filter">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <select className="form-select">
                      <option>Any Device</option>
                      <option>Device 01</option>
                    </select>
                    <select className="form-select">
                      <option>Any Status</option>
                      <option>Status 01</option>
                    </select>
                  </div>
                  <div>
                    <button className="text-btn primary-btn">Updates</button>
                    <button className="text-btn primary-btn">Schedule</button>
                  </div>
                </div>
                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Model</th>
                        <th>Serial</th>
                        <th>MAC</th>
                        <th>Current Firmware</th>
                        <th>New Firmware</th>
                        <th>Update Status</th>
                        <th>Next Steps</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>MR42</td>
                        <td>Q2AB-1234-1234</td>
                        <td>00:11:22:33:44:55</td>
                        <td>5.1.0</td>
                        <td>5.1.2</td>
                        <td>
                          <strong>Pending</strong>
                        </td>
                        <td>Update Now</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="firmware"
                role="tabpanel"
                aria-labelledby="firmware-tab">
                  <div className="ota-filter">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <select className="form-select">
                      <option>Any Device</option>
                      <option>Device 01</option>
                    </select>
                    <select className="form-select">
                      <option>Any Status</option>
                      <option>Status 01</option>
                    </select>
                  </div>
                  <div>
                    <button className="text-btn primary-btn">Add Firmware</button>
                  </div>
                </div>

                {/* ===============UPLOAD FIRMWARE---------- */}
                {/* <div className="customModal">
                  <div className="c-modal-dialog modal-dialog-centered small">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Add Firmware</h5>
                        <button type="button" className="btn-close"></button>
                      </div>
                      <div className="modal-body">
                        <div class="mb-3">
                          <label class="form-label">Devices</label>
                          <select className="form-select">
                            <option>Select Device</option>
                            <option>Device 01</option>
                            <option>Device 02</option>
                            <option>Device 03</option>
                            <option>Device 04</option>
                            <option>Device 05</option>
                          </select>
                        </div>
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Choose File</label>
                          <input class="form-control" type="file" id="formFile" />
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-outline">Cancel</button>
                        <button type="submit" class="text-btn primary-btn">Save</button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="inc-card-table">
                  <table className="full-table m-head">
                    <thead>
                      <tr>
                        <th>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </th>
                        <th>Firmware Version</th>
                        <th>Firmware Name</th>
                        <th>Device</th>
                        <th>Date</th>
                        <th>Upgrade/Downgrade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>5.1.0</td>
                        <td>Q2AB-1234-1234</td>
                        <td>SNMP</td>
                        <td>05-09-2024 10:34:58</td>
                        <td>upgrade</td>                        
                      </tr> 
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>5.1.0</td>
                        <td>Q2AB-1234-1234</td>
                        <td>SNMP</td>
                        <td>05-09-2024 10:34:58</td>
                        <td>upgrade</td>                        
                      </tr> 
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>5.1.0</td>
                        <td>Q2AB-1234-1234</td>
                        <td>SNMP</td>
                        <td>05-09-2024 10:34:58</td>
                        <td>upgrade</td>                        
                      </tr> 
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>5.1.0</td>
                        <td>Q2AB-1234-1234</td>
                        <td>SNMP</td>
                        <td>05-09-2024 10:34:58</td>
                        <td>upgrade</td>                        
                      </tr> 
                      <tr>
                        <td>
                          <label className="checkbox">
                            <input type="checkbox" />
                            <span className="indicator"></span>
                          </label>
                        </td>
                        <td>5.1.0</td>
                        <td>Q2AB-1234-1234</td>
                        <td>SNMP</td>
                        <td>05-09-2024 10:34:58</td>
                        <td>upgrade</td>                        
                      </tr>                     
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="scheduled"
                role="tabpanel"
                aria-labelledby="scheduled-tab">
                <div className="tab-pagination">
                  <div className="inc-card-table">
                    <table className="full-table m-head">
                      <thead>
                        <tr>
                          <th>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </th>
                          <th>Model</th>
                          <th>Serial</th>
                          <th>MAC</th>
                          <th>Current Firmware</th>
                          <th>New Firmware</th>
                          <th>Update Status</th>
                          <th>Next Steps</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                        <tr>
                          <td>
                            <label className="checkbox">
                              <input type="checkbox" />
                              <span className="indicator"></span>
                            </label>
                          </td>
                          <td>MR42</td>
                          <td>Q2AB-1234-1234</td>
                          <td>00:11:22:33:44:55</td>
                          <td>5.1.0</td>
                          <td>5.1.2</td>
                          <td>
                            <strong>Pending</strong>
                          </td>
                          <td>Update Now</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-container">
                    <div className="pagination-info">
                    </div>
                    <div className="pagination">
                      <button>Previous</button>
                      <button>Next</button>
                      <button>Last Page</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default OTAManagement;
