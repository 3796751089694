import React from 'react'
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";

function TrafficBandwithExpandation() {
  const [trafficSeries, setTrafficSeries] = useState([]);
  const [trafficCategories, setTrafficCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trafficDuration, setTrafficDuration] = useState("24h");
  const [timeFormat, setTimeFormat] = useState('dd MMM'); // Default format


  useEffect(() => {
    getTrafficBandwidthData(trafficDuration);
  }, [trafficDuration]);

  const getTrafficBandwidthData = async (duration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalBandWidth?commandType=5&duration=${duration}&networkId=6001`
      );
  
      if (response.status === "OK") {
        const data = response.data;
  
        const seriesData = data.map((item) => ({
          name: item.serialNo,
          data: [
            {
              x: item.trafficData.createdAt * 1000, 
              y: item.trafficData.totalTraffic
            }
          ]
        }));
  
        const timestamps = seriesData.flatMap((series) =>
          series.data.map((point) => point.x)
        );
        const uniqueTimestamps = Array.from(new Set(timestamps)).sort(
          (a, b) => a - b
        );
  
        setTrafficCategories(uniqueTimestamps);
        setTrafficSeries(seriesData);
      } else {
        console.error("Failed to fetch traffic data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching traffic data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleTrafficDurationChange = (trafficDuration) => {
    // Update time format based on duration
    if (trafficDuration === '24h' && '8h') {
      setTimeFormat('dd MMM'); 
    } else if (trafficDuration === '7d' && '30d') {
      setTimeFormat('dd MMM'); 
    }
    setTrafficDuration(trafficDuration);
  };
  
  

  const TrafficOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
    labels: {
      format: timeFormat, 
      style: {
        colors: '#9aa0ac',
        fontSize: '12px',
      },
    },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          return new Date(value).toLocaleTimeString(); // Convert timestamp to a readable time format
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.round(val); // Ensure whole numbers
      },
      },
      title: {
        text: "Traffic (KBPS)",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ['#fc7594', '#00aaff', '#ffc107', '#28a745'], // Colors for different series
    zoom: {
      enabled: true,
    },
    toolbar: {
      show: false,
    },
  };
  
  
  

  // const handleTrafficDurationChange = (dur) => {
  //   setTrafficDuration(dur);
  // };
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Traffic Bandwidth
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {trafficDuration === "24h"
                      ? "Last 24 Hrs"
                      : trafficDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleTrafficDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {trafficSeries.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <ApexCharts
                        options={TrafficOptions}
                        series={trafficSeries}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Devices</th>
                          <th>Time</th>
                          <th>Traffic</th>
                        </tr>
                      </thead>
                      <tbody>
                        {trafficSeries && trafficSeries.length > 0 &&
                          trafficSeries.flatMap((seriesItem, seriesIndex) =>
                            seriesItem.data.map((dataItem, dataIndex) => (
                              <tr key={`${seriesIndex}-${dataIndex}`}>
                                <td>{seriesItem.name}</td> {/* Device name */}
                                <td>{new Date(dataItem.x).toLocaleString()}</td> {/* Timestamp */}
                                <td>{dataItem.y}</td> {/* Traffic data */}
                              </tr>
                            ))
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrafficBandwithExpandation;
