import React, { useState, useEffect } from "react";

const WifiSchedule = ({ isVisible: initialVisibility }) => {
  const [showScheduleForm, setShowScheduleForm] = useState(true);
  const [slotsBooked, setSlotsBooked] = useState(false);
  const [schedule, setSchedule] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  useEffect(() => {
    setShowScheduleForm(true);
  }, [initialVisibility]);

  const handleToggleVisibility = () => {
    setShowScheduleForm((prevState) => !prevState);
  };

  const addTimeSlot = (day) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].push({ startTime: "", endTime: "" });
    setSchedule(updatedSchedule);
  };

  const removeTimeSlot = (day, index) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day].splice(index, 1);
    setSchedule(updatedSchedule);
  };

  const handleChange = (day, index, field, value) => {
    const updatedSchedule = { ...schedule };
    updatedSchedule[day][index][field] = value;
    setSchedule(updatedSchedule);
  };

  const saveSchedule = () => {
    setSlotsBooked(true);
    setShowScheduleForm(false);
  };

  const closeSchedule = () => {
    setShowScheduleForm(false);
    setSlotsBooked(false);
  };

  return (
    <div>
      {showScheduleForm && !slotsBooked ? (
        <div>
          <div className="schedule-week">
            <h4>Schedule Name</h4>
            <input
              type="text"
              className="form-control"
              placeholder="Schedule Name"
            />
            <div className="border-line"></div>
            <p>Location Timezone - America/New_York (ET)</p>

            <h4>Days</h4>
            <p>You can add a maximum of 3 time slots per day.</p>
            <div className="schedule-slot-scroller">
              {Object.keys(schedule).map((day) => (
                <div key={day} className="schedule-days">
                  <div className="sc-day-title">
                    <h4>{day}</h4>
                    {schedule[day].length < 3 && (
                      <div>
                        <button
                          className="icon-btn"
                          onClick={() => addTimeSlot(day)}
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="schedule-time">
                    {schedule[day].map((slot, index) => (
                      <div key={index} className="schedule-time-box">
                        <div className="start-time">
                          <h4>Start Time</h4>
                          <input
                            type="text"
                            placeholder="00:00"
                            value={slot.startTime}
                            onChange={(e) =>
                              handleChange(
                                day,
                                index,
                                "startTime",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <div className="end-time">
                          <h4>End Time</h4>
                          <input
                            type="text"
                            placeholder="00:00"
                            value={slot.endTime}
                            onChange={(e) =>
                              handleChange(
                                day,
                                index,
                                "endTime",
                                e.target.value
                              )
                            }
                          />
                        </div>
                        <button onClick={() => removeTimeSlot(day, index)}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="wifi-footer">
            <button
              type="button"
              className="btn btn-outline"
              onClick={closeSchedule}
            >
              Cancel
            </button>
            <button
              type="button"
              className="text-btn primary-btn"
              onClick={saveSchedule}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className="schedule-wrapper">
          {Object.keys(schedule).map((day) =>
            schedule[day].map((slot, index) => (
              <div className="schedule-item" key={`${day}-${index}`}>
                <div className="schedule-icon">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                </div>
                <div className="schedule-detail">
                  <h5>{day}</h5>
                  <p>
                    {slot.startTime} - {slot.endTime}
                  </p>
                </div>
                <div className="schedule-button">
                  <button>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button>
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default WifiSchedule;
