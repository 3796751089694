import React, { useEffect, useState } from 'react';
import allApi from '../../../api/allApi';

const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  
  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString();
  
  if (isToday) return 'Today';
  if (isYesterday) return 'Yesterday';
  
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'long',
    year: 'numeric'
  });
};

function Notification() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const api = new allApi();
        const response = await api.getRequest("api/vlan/allNotifications");
        setNotifications(response); 
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  const groupedNotifications = notifications.reduce((acc, notification) => {
    const dateKey = formatDate(notification.createdAt);
    if (!acc[dateKey]) acc[dateKey] = [];
    acc[dateKey].push(notification);
    return acc;
  }, {});

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Notification</h3>
          </div>
          <div className="inc-card-body">
            <div className='notification-container w-scroll'>
              {Object.keys(groupedNotifications).length > 0 ? (
                Object.entries(groupedNotifications).map(([date, notifications]) => (
                  <div key={date} className='notification-group'>
                    <h4>{date} <span>{notifications.length}</span></h4>
                    {notifications.map(notification => (
                      <div key={notification.notificationId} className='notification-list'>
                        <div className='n-info'>
                        <h6>{notification.notificationName || notification.deviceName || "N/A"}<small>{formatTime(notification.createdAt)}</small></h6>
                        <p>{notification.description || "No discription found"}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p>No notifications available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
