import React from "react";

function shareDiagnosticInformation() {
  return (
    <div className="inc-card-body">
      <div className="under-development">Share Diagnostic Information</div>
    </div>
  );
}

export default shareDiagnosticInformation;