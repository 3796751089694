import React from "react";
import portpic from "../../../../assets/images/wired.svg";
import errorIcon from "../../../../assets/images/error-2.png";

function downtimeSchedules() {
  return (
    <div>

      {/* <div className="poe-schedule-banner">
        <div>
          <img src={portpic} alt="Port" />
          <p>Create a PoE downtime schedule to disable PoE ports for enhanced security and energy savings.</p>
          <button className="text-btn primary-btn">
            Create PoE Downtime Schedule
          </button>
        </div>
      </div> */}

      <div className="poe-schedule">
        <div className="accordion mt-4" id="poeSchedule">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Testing
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#poeSchedule">
              <div className="accordion-body">

                <div className="schedule-action">
                  <div className="tab-form">
                    <div className="switch-box">
                      <strong>Allow Broadcast/Multicast Traffic - Wired</strong>
                      <label className="ice-switch">
                        <input type="checkbox" />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                  <div className="schedule-btn">
                    <button type="button" className="btn btn-outline">Edit</button>
                    <button type="button" className="btn btn-outline">Edit Ports</button>
                    <button type="button" className="btn btn-outline">Delete</button>
                  </div>
                </div>
                <div className="poe-schdule-time">
                  <div>
                    <span>Time Range</span>
                    <strong>05:30 a.m. - 05:32 a.m.</strong>
                  </div>
                  <div>
                    <span>Recurrence</span>
                    <strong>Sep 03, 2024 - Sep 03, 2024</strong>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Testing2
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#poeSchedule"
            >
              <div className="accordion-body">

                <div className="schedule-action">
                  <div className="tab-form">
                    <div className="switch-box">
                      <strong>Allow Broadcast/Multicast Traffic - Wired</strong>
                      <label className="ice-switch">
                        <input type="checkbox" />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                  <div className="schedule-btn">
                    <button type="button" className="btn btn-outline">Edit</button>
                    <button type="button" className="btn btn-outline">Edit Ports</button>
                    <button type="button" className="btn btn-outline">Delete</button>
                  </div>
                </div>
                <div className="poe-schdule-time">
                  <div>
                    <span>Time Range</span>
                    <strong>05:30 a.m. - 05:32 a.m.</strong>
                  </div>
                  <div>
                    <span>Recurrence</span>
                    <strong>Sep 03, 2024 - Sep 03, 2024</strong>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <p className="mt-4">To reset a switch port PoE, go to switch Device Dashboard <i class="fa fa-angle-right" aria-hidden="true"></i> PoE Management.</p>
      </div>

      {/* <div className="customModal">
        <div className="c-modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Create New PoE Downtime Schedule</h5>
              <button type="button" className="btn-close"></button>
            </div>
            <div className="modal-body add-ssid-body">

              <p>Set up a schedule to disable PoE power on selected ports.</p>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <label htmlFor="ScheduleName" className="col-form-label">Schedule Name</label>
                  <input type="text" className="form-control" name="ScheduleName" placeholder="Schedule Name" />
                </div>
              </div>

              <div className="border-line"></div>
              <h4 className="mt-3">Downtime Range</h4>
              <p>Select the start and end of the time range in which PoE ports are disabled for the day.</p>

              <div className="row mb-3">
                <div className="col-lg-6">
                  <div className="tab-form">
                    <div className="switch-box">
                      <strong>All Day</strong>
                      <label className="ice-switch">
                        <input type="checkbox" />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row mb-4">
                <div className="col-lg-6">
                  <label htmlFor="StartTime" className="col-form-label">Start Time</label>
                  <input type="text" className="form-control" name="StartTime" placeholder="Start Time" />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="EndTime" className="col-form-label">End Time</label>
                  <input type="text" className="form-control" name="EndTime" placeholder="End Time" />
                </div>
              </div>

              <div className="border-line"></div>

              <h4 className="mt-3">Recurrence</h4>
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="Recurrence" className="col-form-label">Recurrence Type</label>
                  <select id="securityAuthentication" className="form-select">
                    <option>None</option>
                    <option>Daily</option>
                    <option>Weekly</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label htmlFor="StartOn" className="col-form-label">Start On</label>
                  <input type="text" className="form-control" name="StartOn" placeholder="Start On" />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="EndOn" className="col-form-label">End On</label>
                  <input type="text" className="form-control" name="EndOn" placeholder="End On" />
                </div>
              </div>


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline">
                Cancel
              </button>
              <button type="button" className="text-btn primary-btn">
                Save and Choose Ports
              </button>
              <button type="button" className="text-btn primary-btn">
                Save
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="customModal confirm-modal">
        <div className="modal-dialog modal-dialog-centered small">
          <div className="modal-content inc-modal">
            <div className="modal-body">
              <img src={errorIcon} alt="error" />
              <p>This schedule will be deleted from the network., testkj will no longer follow any PoE Schedules.</p>
              <h3>Do you want to continue?</h3>
              <div className="modal-footer">
                <button type="button" class="btn btn-outline">No</button>
                <button className="text-btn primary-btn">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="customModal">
        <div className="c-modal-dialog modal-dialog-centered small">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Apply Schedule</h5>
              <button type="button" className="btn-close"></button>
            </div>
            <div className="modal-body">
              <p>Select the ports to disable for "Testing".</p>
              <ul>
                <li>Selected ports will be disabled according to the schedule.</li>
                <li>Unselected ports are not affected by this schedule.</li>
              </ul>
              <p>No switch found to configure.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline">Cancel</button>
              <button type="submit" class="text-btn primary-btn">Save</button>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default downtimeSchedules;