import React from "react";

function HTTPS() {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>HTTPS Management</h3>
          </div>
          <div className="inc-card-body">
            <div className="under-development">
                This page is under Development
            </div>          
          </div>
        </div>
      </div>
    </div>
  );
}

export default HTTPS;
