import React, { useState, useEffect, useRef } from "react";
import locationMap from "../../../assets/images/map.png";
import ssidGraph from "../../../assets/images/ssid-graph.png";
import deviceImage from "../../../assets/images/device.png";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import towerIconSvg from './tower.svg';
import 'leaflet/dist/leaflet.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  BarChart,
  Bar,
  Legend,
  ResponsiveContainer,
} from "recharts";
import allApi from "../../../api/allApi";
import Loader from "../common/Loader";
import StackedHorizontalChart from "../dashboard/StackedHorizontalChart";
import { useLocation } from "react-router-dom";

function DeviceDashboard() {
  const location = useLocation();
  const { deviceId } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [dataUsage, setDataUsage] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("1 Week");
  const [upDownData, setUpDownData] = useState([]);
  const [colorRanges, setColorRanges] = useState([]);
  const [connectedClients, setConnectedClients] = useState([]);
  const nodes = [
    { id: 'parent', label: 'Tower', position: [28.4303577,77.0125097], icon: towerIconSvg },];
  const icon = (iconUrl) => L.icon({
    iconUrl: iconUrl,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });

  useEffect(() => {
    console.log("Device ID:", deviceId);
    if (deviceId) {
      fetchDeviceDetails(deviceId);
      fetchData(deviceId);
      fetchConnectedClients(deviceId);
      handleDataUsage(deviceId);
    }
  }, [deviceId, selectedInterval]);

  const fetchDeviceDetails = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/snmp/device/${deviceId}`);
      console.log("Device Details Response:", response);
      if (response) {
        setDeviceDetails(response);
      } else {
        console.error("No data returned from API");
      }
    } catch (error) {
      console.error("Error fetching device details:", error.message || error);
    } finally {
      setLoading(false);
    }
  };


  const handleDataUsage = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(
        `api/TxRx/${deviceId}/${selectedInterval}`
      );
      if (response) {
        const formattedData = response.map((entry) => ({
          time: new Date(entry.updatedAt).toLocaleTimeString(),
          txBytes: entry.txBytes,
          rxBytes: entry.rxBytes,
        }));
        setDataUsage(formattedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data usage:", error);
      setLoading(false);
    }
  };

  const fetchData = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/health/${deviceId}`);
      if (response) {
        const formattedData = response.map((entry) => ({
          updatedAt: new Date(entry.updatedAt).getTime(),
          status: entry.devHealth === 1 ? "online" : "offline",
        }));
        setUpDownData(formattedData);
        generateColorRanges(formattedData);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchConnectedClients = async (deviceId) => {
    setLoading(true);
    try {
      const api = new allApi();
      const response = await api.getRequest(`api/connectedClients/${deviceId}`);
      if (response) {
        setConnectedClients(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching connected clients:", error);
      setLoading(false);
    }
  };

  const generateColorRanges = (data) => {
    let colorRanges = [];
    let lastState = data[0].status;
    let from = data[0].updatedAt;

    data.forEach((entry, index) => {
      if (entry.status !== lastState || index === data.length - 1) {
        colorRanges.push({
          from: from,
          to: entry.updatedAt,
          color: lastState === "online" ? "#00D76F" : "#fc3858",
        });
        from = entry.updatedAt;
        lastState = entry.status;
      }
    });

    setColorRanges(colorRanges);
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  return (
    <div>
      <div className="row mb-4">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                {deviceDetails && (
                  <div className="dashboard-device-icon">
                    <img src={deviceImage} alt="" />
                    <strong>{deviceDetails?.deviceName || "N/A"}</strong>
                  </div>
                )}

                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceDetails?.serialNumber || "N/A"}</strong>
                      <span>Serial Number</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.ipv4 || "N/A"}</strong>
                      <span>IP Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.macAddress || "N/A"}</strong>
                      <span>Mac Address</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.softwareVersion || "N/A"}</strong>
                      <span>Firmware version</span>
                    </div>
                  </div>
                  <div className="detail-list">
                    <div>
                      <strong>{deviceDetails?.dev_cpu_load || "N/A"}</strong>
                      <span>CPU Utilization</span>
                    </div>
                    <div>
                      <strong>{deviceDetails?.dev_mem_load || "N/A"}</strong>
                      <span>Memory Utilization</span>
                    </div>
                    <div>
                      <strong>{deviceDetails && deviceDetails.health}</strong>
                      <span>Device Health</span>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <strong>Up/Down Time</strong>
                  </div>

                  <div className="graph">
                    {upDownData.length === 0 ? (
                      <div>No data to display</div>
                    ) : (
                      <div>
                        {loading ? (
                          <Loader />
                        ) : (
                          <StackedHorizontalChart
                            height={80}
                            data={upDownData}
                            colorRanges={colorRanges}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-8">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Data Usage</h3>
              <span
                className="dropdown"
                id="trendDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedInterval}{" "}
                <i className="fa fa-angle-down" aria-hidden="true"></i>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="trendDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleIntervalChange("6 Hours")}
                    >
                      6 Hours
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleIntervalChange("12 Hours")}
                    >
                      12 Hours
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleIntervalChange("24 Hours")}
                    >
                      24 Hours
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleIntervalChange("1 Week")}
                    >
                      1 Week
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => handleIntervalChange("1 Month")}
                    >
                      1 Month
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : dataUsage.length === 0 ? (
                <div className="no-data-box">No data to display</div>
              ) : (
                  <div>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart
                        data={dataUsage}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis tickFormatter={(value) => `${value} MB`} />
                        <Tooltip formatter={(value, name) => [`${value} MB`, name]}/>
                        {/* Orange line for Upload (txBytes) */}
                        <Line
                          type="monotone"
                          dataKey="txBytes"
                          stroke="#FF9050" // Orange color
                          name="Upload (txBytes)"
                        />
                        {/* Blue line for Download (rxBytes) */}
                        <Line
                          type="monotone"
                          dataKey="rxBytes"
                          stroke="#4791FF" // Blue color
                          name="Download (rxBytes)"
                        />
                      </LineChart>
                    </ResponsiveContainer>                
                    <div className="data-label">
                      <span className="download">
                        <i></i>Download
                      </span>
                      <span className="upload">
                        <i></i>Upload
                      </span>
                    </div>
                  </div>
                  )}
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Connected Client Per SSID</h3>
              <span
                className="dropdown"
                id="trendDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Today <i className="fa fa-angle-down" aria-hidden="true"></i>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="trendDropdown"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      1 Month
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      3 Month
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      6 Month
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      12 Month
                    </a>
                  </li>
                </ul>
              </span>
            </div>
            <div className="inc-card-body">
              <img src={ssidGraph} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Connected Client</h3>
            </div>
            <div className="inc-card-body">
              {loading ? (
                <Loader />
              ) : (
                connectedClients.map((client, index) => (
                  <div className="client-list" key={index}>
                    <div className="client-icon">
                      <i className="fa fa-tablet" aria-hidden="true"></i>
                    </div>
                    <div className="client-info">
                      <strong>{client}</strong>
                      <span>{client}</span>
                    </div>
                    <div className="client-usage">
                      <strong>1 GB</strong>
                      <span>03 Hrs 30 Mins</span>
                    </div>
                    <div className="action">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>Location</h3>
            </div>
            <div className="inc-card-body">
              <div >
                <MapContainer center={[28.4303577,77.0125097]} zoom={12} style={{ height: '200px', width: '100%' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {nodes.map((node) => (
                    <Marker key={node.id} position={node.position} icon={icon(node.icon)}>
                    </Marker>
                  ))}
                </MapContainer>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceDashboard;
