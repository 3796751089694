import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import allApi from "../../../api/allApi";
// import { Button } from "bootstrap";

function SnmpDevice() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [parameters, setParameters] = useState([]);
  const location = useLocation();
  const initialDeviceData = location.state?.deviceInfo || {};
  const initialGatewayId = location.state?.gateway_id || null;
  const [alarms, setAlarms] = useState([]);
  const [counts, setCounts] = useState({});
  const [gatewayDetails, setGatewayDetails] = useState(null);
  const gatewayId = location?.state?.gateway_id;
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const navigate = useNavigate();
  const [deviceId, setDeviceId] = useState(initialDeviceData.deviceId || null);
  const [protocolType, setProtocolType] = useState(initialDeviceData.protocolType || null)
  const [managedByGateway, setManagedByGateway] = useState(
    location?.state?.managed_by_gateway || null
  );

  useEffect(() => {
    if (deviceId && location.state?.protocolType) {
      fetchAlarms(location.state.protocolType);
    }
  }, [deviceId, location.state?.protocolType]);

  useEffect(() => {
    if (initialGatewayId) {
      fetchGatewayDetails(initialGatewayId);
    }
  }, [initialGatewayId]);

  useEffect(() => {
    if (managedByGateway !== null) {
      setManagedByGateway(location?.state?.managed_by_gateway);
    }
  }, [location.state?.managed_by_gateway]);

  const handleDeviceInfo = async () => {
    try {
      const api = new allApi();
      const payload = {
        protocolType: location.state.protocolType,
        deviceName: location.state.deviceName,
      };
      const response = await api.getRequest(`api/device`, payload);
      if (response) {
        setDeviceData(response);
        setDeviceId(response.deviceId);
      } else {
        console.error("Error fetching device data.");
      }
    } catch (error) {
      console.error("Error handling device:", error);
    }
  };

  const fetchAlarms = async (protocolType) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(`api/alarms/device`, { deviceId, protocolType });
      if (response) {
        setAlarms(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching alarms data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const counts = {
      Critical: alarms.filter((alarm) => alarm.severityLevel.toUpperCase() === "CRITICAL").length,
      Major: alarms.filter((alarm) => alarm.severityLevel.toUpperCase() === "MAJOR").length,
      Minor: alarms.filter((alarm) => alarm.severityLevel.toUpperCase() === "MINOR").length,
      Info: alarms.filter((alarm) => alarm.severityLevel.toUpperCase() === "INFO").length,
    };
    console.log("newcounts", counts);
    setCounts(counts);
  }, [alarms]);

  const fetchAlarmsbySeverity = async (severityLevelText) => {
    try {
      setLoading(true);
      const severityMapping = {
        Critical: 1,
        Major: 2,
        Minor: 3,
        Info: 4,
      };

      const formattedSeverityLevelText = severityLevelText.charAt(0).toUpperCase() + severityLevelText.slice(1).toLowerCase();

      console.log("Formatted severity level text:", formattedSeverityLevelText);

      const severityLevel = severityMapping[formattedSeverityLevelText];
      const api = new allApi();
      const response = await api.getRequest(
        `api/alarms?deviceId=${deviceId}&protocolType=${location.state.protocolType}&severity_level=${severityLevel}`,
      );

      if (response) {
        const severityAlerts = response;
        navigate("/alarms", { state: { severityAlerts } });
        console.log("severityalerts", severityAlerts);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching alarms data:", error);
      setLoading(false);
    }
  };

  const handleViewAll = async (severityLevel) => {
    await fetchAlarmsbySeverity(severityLevel);
  }

  useEffect(() => {
    console.log("Gateway Details:", gatewayDetails);
  }, [gatewayDetails]);

  useEffect(() => {
    if (location.state && location.state.protocolType) {
      console.log("Location State:", location.state);
      handleDeviceInfo();
    }
  }, []);

  useEffect(() => {
    if (gatewayId) {
      fetchGatewayDetails(gatewayId);
    }
  }, [gatewayId]);

  useEffect(() => {
    if (location.state && location?.state?.managed_by_gateway !== null) {
      setManagedByGateway(location?.state?.managed_by_gateway);
    }
  }, [location?.state?.managed_by_gateway]);

  const fetchGatewayDetails = async (gateway_id) => {
    try {
      setLoading(true);
      const api = new allApi();
      console.log("Fetching gateway details");
      const response = await api.getRequest(`api/gateway/${gateway_id}`);
      if (response) {
        setGatewayDetails(response);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching gateway details:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Gateway Details:", gatewayDetails);
  }, [gatewayDetails]);
  return (
    <div>
      <div className="device-info-wrap mb-4">
        <div className="inc-card info-card-4">
          <div className="inc-card-title">
            <h3>{deviceData?.serialNumber}</h3>
          </div>
          <div className="inc-card-body">
            <div className="device-info">
              <div>
                <strong>Device Name</strong>
                <span>{deviceData.deviceName}</span>
              </div>
              <div>
                <strong>Protocol Type</strong>
                <span>{deviceData.protocolType || "SNMP"}</span>
              </div>
              <div>
                <strong>Product Class</strong>
                <span>{deviceData.productClass || "N/A"}</span>
              </div>
              {/* <div>
                <strong>Serial Number</strong>
                <span>{deviceData.serialNumber || "N/A"}</span>
              </div> */}
              <div>
                <strong>Software Version</strong>
                <span>{deviceData.softwareVersion || "N/A"}</span>
              </div>
              <div>
                <strong>Hardware Version</strong>
                <span>{deviceData.hardwareVersion || "N/A"}</span>
              </div>
              <div>
                <strong>IP Address</strong>
                <span>{deviceData.ip || "N/A"}</span>
              </div>
              <div>
                <strong>MAC Address</strong>
                <span>{deviceData.macAddress || "N/A"}</span>
              </div>
              <div>
                <strong>Last Inform</strong>
                <span>{deviceData.lastInform || "N/A"}</span>
              </div>
              <div>
                <strong>Health</strong>
                <span
                  style={{
                    color: deviceData.health === "Online" ? "green" : "red",
                  }}
                >
                  {deviceData.health === "Online" ? "Online" : "Offline"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="inc-card info-card-8">
          <div className="inc-card-title">
            <h3>Lan Host</h3>
          </div>
          <div className="inc-card-body">
            <div className="inc-card-table">
              <table>
                <thead>
                  <tr>
                    <th>Host Name</th>
                    <th>IP Address</th>
                    <th>MAC Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Host Name 1</td>
                    <td>192.168.37.32</td>
                    <td>00:06:ae:8e:38:b0</td>
                  </tr>
                  <tr>
                    <td>Host Name 2</td>
                    <td>192.168.37.32</td>
                    <td>00:06:ae:8e:38:b0</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {managedByGateway !== null ? (
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>Edge</h3>
              </div>
              <div className="inc-card-body">
                <div className="inc-card-table">
                  <table className="m-head snmp-table">
                    <thead>
                      <tr>
                        <th>Gateway Name</th>
                        <th>Organisation Name</th>
                        <th>Site Name</th>
                        <th>Publisher Topic</th>
                        <th>Subscriber Topic</th>
                        <th>Username</th>
                        <th>Agent Mail ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{gatewayDetails?.gateway_name}</td>
                        <td>{gatewayDetails?.organisation_name}</td>
                        <td>{gatewayDetails?.site_name}</td>
                        <td>{gatewayDetails?.publisher_topic}</td>
                        <td>{gatewayDetails?.subscriber_topic}</td>
                        <td>{gatewayDetails?.username}</td>
                        <td>{gatewayDetails?.agent_mail}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="inc-card">
              <div className="inc-card-title">
                <h3>No Edge Found</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mb-4">
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon pie">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Critical</p>
                  <div className="task-count">
                    <h2>{counts?.Critical}</h2>
                    <Link
                      onClick={() => handleViewAll("CRITICAL")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon fire">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Major</p>
                  <div className="task-count">
                    <h2>{counts?.Major}</h2>
                    <Link
                      onClick={() => handleViewAll("MAJOR")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon flag">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Minor</p>
                  <div className="task-count">
                    <h2>{counts?.Minor}</h2>
                    <Link
                      onClick={() => handleViewAll("Minor")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="inc-card">
            <div className="inc-card-body">
              <div className="alarm-box">
                <span className="task-icon line">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </span>
                <div>
                  <p className="task-type">Info</p>
                  <div className="task-count">
                    <h2>{counts?.Info}</h2>
                    <Link
                      onClick={() => handleViewAll("INFO")}
                    >
                      View All{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
                <i className="fa fa-line-chart" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="inc-card">
            <div className="inc-card-title">
              <h3>All Parameters</h3>
              <div className="inc-card-button">
                <button className="icon-btn">
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className="inc-card-body">
              <div className="dashboard-device-info">
                <div className="dashboard-device-detail">
                  <div className="detail-list">
                    <div>
                      <strong>{deviceData.deviceName || "N/A"}</strong>
                      <span>Device Name</span>
                    </div>
                    <div>
                      <strong>{deviceData.protocolType || "N/A"}</strong>
                      <span>Protocol Type</span>
                    </div>
                    <div>
                      <strong>{deviceData.productClass || "N/A"}</strong>
                      <span>Product Class</span>
                    </div>
                    <div>
                      <strong>{deviceData.serialNumber || "N/A"}</strong>
                      <span>Serial Number</span>
                    </div>
                    <div>
                      <strong>{deviceData.softwareVersion || "N/A"}</strong>
                      <span>Software Version</span>
                    </div>
                    <div>
                      <strong>{deviceData.hardwareVersion || "N/A"}</strong>
                      <span>Hardware Version</span>
                    </div>
                    <div>
                      <strong>{deviceData.ipv4 || "N/A"}</strong>
                      <span>IP Address</span>
                    </div>
                    <div>
                      <strong>{deviceData.macAddress || "N/A"}</strong>
                      <span>MAC Address</span>
                    </div>
                    <div>
                      <strong>{deviceData.lastInform || "N/A"}</strong>
                      <span>Last Inform</span>
                    </div>
                    <div>
                      <strong
                        style={{
                          color:
                            deviceData.health === "Online" ? "green" : "red",
                        }}
                      >
                        {deviceData.health || "N/A"}
                      </strong>
                      <span>Health</span>
                    </div>
                    <div>
                      <strong>{deviceData.managed_by_gateway || "N/A"}</strong>
                      <span>Managed By Gateway</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_mode || "N/A"}</strong>
                      <span>Device Mode</span>
                    </div>
                    <div>
                      <strong>{deviceData.temperature || "N/A"}</strong>
                      <span>Temperature</span>
                    </div>
                    <div>
                      <strong>{deviceData.port || "N/A"}</strong>
                      <span>Port</span>
                    </div>
                    <div>
                      <strong>{deviceData.tx_power || "N/A"}</strong>
                      <span>TX Power</span>
                    </div>
                    <div>
                      <strong>{deviceData.currentmcs_index || "N/A"}</strong>
                      <span>Current MCS Index</span>
                    </div>
                    <div>
                      <strong>{deviceData.snr || "N/A"}</strong>
                      <span>SNR</span>
                    </div>
                    <div>
                      <strong>{deviceData.cinr || "N/A"}</strong>
                      <span>CINR</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_added || "N/A"}</strong>
                      <span>Device Added</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_type || "N/A"}</strong>
                      <span>Device Type</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_datetime || "N/A"}</strong>
                      <span>Device DateTime</span>
                    </div>
                    <div>
                      <strong>{deviceData.client_mac || "N/A"}</strong>
                      <span>Client MAC</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_up_time || "N/A"}</strong>
                      <span>Device Up Time</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_down_time || "N/A"}</strong>
                      <span>Device Down Time</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_power || "N/A"}</strong>
                      <span>Device Power</span>
                    </div>
                    <div>
                      <strong>{deviceData.connection_type || "N/A"}</strong>
                      <span>Connection Type</span>
                    </div>
                    <div>
                      <strong>{deviceData.firmware_version || "N/A"}</strong>
                      <span>Firmware Version</span>
                    </div>
                    <div>
                      <strong>{deviceData.device_status || "N/A"}</strong>
                      <span>Device Status</span>
                    </div>
                    <div>
                      <strong>
                        {deviceData.configuration_version || "N/A"}
                      </strong>
                      <span>Configuration Version</span>
                    </div>
                    <div>
                      <strong>{deviceData.serial_number || "N/A"}</strong>
                      <span>Serial Number</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SnmpDevice;
