import React from 'react'
import { useEffect, useState } from "react";
// import Chart from "react-apexcharts";
import ApexCharts from "react-apexcharts";
import allApi from '../../../../api/allApi';
import Loader from '../../common/Loader';

function WiredTrafficExpansion() {
  const [trafficSeries, setTrafficSeries] = useState([]);
  const [trafficCategories, setTrafficCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trafficDuration, setTrafficDuration] =
    useState("24h");

  useEffect(() => {
    getTraffic(trafficDuration);
  }, [trafficDuration]);

  const handleTrafficDurationChange = (newDuration) => {
    setTrafficDuration(newDuration);
  };


  const getTraffic = async (newDuration) => {
    try {
      setLoading(true);
      // setRefreshing(true);
      const api = new allApi();
      const trafficData = await api.getRequest(`api/wired/dashboard/traffic/${newDuration}`);

      if (trafficData && Array.isArray(trafficData)) {
        if (trafficData.length === 0) {
          // Handle no data scenario
          console.log("No traffic data available.");
          setTrafficSeries([]); // Set to empty array
          setTrafficCategories([]);
        } else {
          const aggregatedData = trafficData.reduce((acc, item) => {
            const date = new Date(item.hour).toLocaleDateString(undefined, {
              month: 'short',
              day: 'numeric',
            });

            if (!acc[date]) {
              acc[date] = { total_bandwidth: 0, userId: item.userId };
            }

            acc[date].total_bandwidth += item.total_bandwidth;
            return acc;
          }, {});

          const categories = Object.keys(aggregatedData);
          const seriesData = categories.map((date) => ({
            x: date,
            y: aggregatedData[date].total_bandwidth,
            z: aggregatedData[date].userId,
          }));

          setTrafficSeries([{ data: seriesData }]);
          setTrafficCategories(categories);
        }
      } else {
        console.error("Traffic data is not an array:", trafficData);
        setTrafficSeries([]); // Set to empty array
        setTrafficCategories([]);
      }
    } catch (error) {
      console.error("Error fetching traffic", error);
      setTrafficSeries([]); // Set to empty array
      setTrafficCategories([]);
    } finally {
      setLoading(false);
      // setRefreshing(false);
    }
  };

  const trafficOptions = {
    chart: {
      type: "line",
      height: 135,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: trafficCategories,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      title: {
        text: "Traffic",
      },
    },
    tooltip: {
      y: {
        title: {
          formatter: function () {
            return 'Total Bandwidth : ';
          }
        }
      },
      z: {
        title: 'UserId :',
        formatter: function (value) {
          return value;
        }
      }
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: trafficSeries.map((_, index) =>
      index === 0 ? "#fc7594" : "#00aaff"
    ),
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  };


  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>Traffic</h3>
            <div className="inc-card-button">
              <span
                className="dropdown"
                id="clientDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {trafficDuration === "24h"
                    ? "24h"
                    : trafficDuration}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </span>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleTrafficDurationChange("8h")
                      }
                    >
                      Last 8 Hrs
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleTrafficDurationChange("24h")
                      }
                    >
                      Last 24 Hrs
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleTrafficDurationChange("7d")
                      }
                    >
                      Last 7 Days
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() =>
                        handleTrafficDurationChange("30d")
                      }
                    >
                      Last 30 Days
                    </a>
                  </li>
                </ul>
              </span>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                <div className="inc-card-body">
                  {loading ? (
                    <Loader />
                  ) : (
                    <div>
                      {trafficSeries.length === 0 ? (
                        <div className="no-data-box">
                          <div className="no-data-text">No Data Available</div>
                        </div>
                      ) : (
                        <ApexCharts
                          options={trafficOptions}
                          series={trafficSeries}
                          type="line"
                          height={250}

                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>User Id</th>
                          <th>Date</th>
                          <th>Total Bandwidth</th>
                        </tr>
                      </thead>
                      <tbody>
                        {trafficSeries && trafficSeries.length > 0 &&
                          trafficSeries[0].data &&
                          trafficSeries[0].data.map((item, index) => (
                            <tr key={index}>
                              <td>{item.z}</td>
                              <td>{item.x}</td>
                              <td>{item.y}</td>

                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WiredTrafficExpansion;
