import React from 'react'
import Chart from "react-apexcharts";
import Loader from "../../common/Loader";
import allApi from "../../../../api/allApi";
import { useNavigate } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { useEffect, useState } from "react";

function ClientExpandation() {
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [clientsDuration, setClientsDuration] = useState("24h");
  const [duration, setDuration] = useState("24h");
  useEffect(() => {
    getClientDetail(clientsDuration);
  }, [clientsDuration]);

  const getClientDetail = async (duration) => {
    try {
      setLoading(true);
      const api = new allApi();
      const response = await api.getRequest(
        `api/wireless/totalClients?commandType=5&duration=${duration}&networkId=6001`
      );
  
      if (response.status === "OK") {
        const data = response.data;
  
        const categories = new Set();
        const seriesData = {};
  
        data.forEach((item) => {
          const timestamp = item.clients.timestamp * 1000; // Convert timestamp to milliseconds
          const serialNo = item.serialNo;
          const totalClients = item.clients.totalClient || 0; // Use 0 if totalClient is not present
  
          // Initialize series data for this serial number if not present
          if (!seriesData[serialNo]) {
            seriesData[serialNo] = {
              name: serialNo,
              data: []
            };
          }
  
          // Add data point for the current timestamp and total clients
          seriesData[serialNo].data.push({
            x: timestamp,
            y: totalClients
          });
  
          // Add timestamp to categories
          categories.add(timestamp);
        });
  
        // Convert seriesData to an array of series objects
        const series = Object.values(seriesData);
  
        // Sort categories
        const sortedCategories = Array.from(categories).sort((a, b) => a - b);
  
        // Set categories and series data
        setCategories(sortedCategories);
        setSeries(series);
      } else {
        console.error("Failed to fetch data:", response.message);
      }
    } catch (error) {
      console.error("Error fetching client detail data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  
  

  const clientsOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: 'dd MMM', // Format for x-axis labels: hours and minutes
        style: {
          colors: '#9aa0ac',
          fontSize: '12px',
        },
      },
      axisBorder: {
        show: true,
        color: '#d6d6d6',
      },
      axisTicks: {
        show: true,
        color: '#d6d6d6',
      },
      tooltip: {
        enabled: true,
        formatter: (value) => {
          // Convert the timestamp to a Date object and ensure it's in the correct timezone
          const date = new Date(value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
          return date; // Return the correctly formatted date
        },
      },
    },
     yaxis: {
      labels: {
        formatter: function (val) {
          return val !== undefined ? val.toFixed(0) : "N/A"; // Use integer values
        },
      },
      title: {
        text: "Number of Clients",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
    colors: ['#fc7594', '#00aaff', '#ffc107', '#28a745'], // Colors for different series
    zoom: {
      enabled: true,
    },
  };
  
const handleClientsDurationChange = (newDuration) => {
    setClientsDuration(newDuration);
};

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="inc-card ch-100">
          <div className="inc-card-title">
            <h3>
              Clients
            </h3>
            <div className="card-title-dropdown">
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    All <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        2.4 HHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        5 GHz
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        6 GHz
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="btn-group">
                <span
                  className="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    {clientsDuration === "24h"
                      ? "Last 24 Hrs"
                      : clientsDuration}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </span>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("8h")}
                      >
                        Last 8 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("24h")}
                      >
                        Last 24 Hrs
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("7d")}
                      >
                        Last 7 Days
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => handleClientsDurationChange("30d")}
                      >
                        Last 30 Days
                      </a>
                    </li>
                  </ul>
                </span>
              </div>
              <input className="list-search" type="text" />
              <button className="icon-btn" title="Refresh">
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
              <button className="icon-btn" title="Filter">
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="inc-card-body">
            <div className='row'>
              <div className='col-lg-8'>
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    {series.length === 0 ? (
                      <div className="no-data-box">
                        <div className="no-data-text">No Data Available</div>
                      </div>
                    ) : (
                      <Chart
                        options={clientsOptions}
                        series={series}
                        type="line"
                        height={170}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className='col-lg-4'>
                <div className='round-border'>
                  <div className="inc-card-table">
                    <table className="m-head">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>No of Clients</th>
                        </tr>
                      </thead>
                      <tbody>
                        {series && series.length > 0 &&
                          series.flatMap((seriesItem) => {
                            // Create a map to aggregate client counts by date
                            const dateToClientCountMap = seriesItem.data.reduce((acc, dataItem) => {
                              const dateStr = new Date(dataItem.x).toLocaleDateString(); // Format date as needed
                              if (!acc[dateStr]) {
                                acc[dateStr] = 0;
                              }
                              acc[dateStr] += dataItem.y; // Aggregate client counts
                              return acc;
                            }, {});

                            // Map to table rows
                            return Object.entries(dateToClientCountMap).map(([date, count], index) => (
                              <tr key={`${seriesItem.name}-${index}`}>
                                <td>{date}</td> {/* Date */}
                                <td>{count}</td> {/* Total number of clients */}
                              </tr>
                            ));
                          })
                        }
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientExpandation;
